import { createRouter, createWebHashHistory, createWebHistory} from 'vue-router'
import Layout from "../views/layout.vue";
import PrivacyPolicy from "../views/PrivacyPolicy/PrivacyPolicy.vue";
import Sql from "../views/Sql/sql.vue";
import Docs from "../views/Docs/index.vue";

// const routes = [  
//   {
//     path: '/',
//     name: 'layout',
//     redirect: { path: "/sql" },
//     component: Layout,
//     children: [
//       {
//         path: '/start',
//         component: HomeView
//       },
//       {
//         path: '/sql',
//         component: Sql
//       },
//       {
//         path: '/mm',
//         component: Mm
//       },
//       {
//         path: '/rdf',
//         component: Rdf
//       },
//       {
//         path: '/im',
//         component: Im
//       },
//       {
//         path: '/docs',
//         component: Docs
//       },
//     ]
//   },
//   {
//     path: '/privacyPolicy',
//     name: 'PrivacyPolicy',
//     component: PrivacyPolicy
//   },
  
// ]

const router = createRouter({
  // history: createWebHashHistory(),
  // history: createWebHistory(import.meta.env.BASE_URL),
  history: createWebHashHistory(),
  routes : [  
    {
      path: '/',
      name: 'app',
      component: Layout,
      children: [
        {
          name: 'Sql',
          path: '',
          component: Sql
        },
        {
          name: 'Docs',
          path: '/docs',
          component: Docs
        },
      ]
    },
    {
      path: '/privacyPolicy',
      name: 'PrivacyPolicy',
      component: PrivacyPolicy
    },   
  ],
})

export default router
