/* eslint-disable */
<template>
  <v-app-bar :elevation="0">
    <v-row :class="GET_PAGE =='rdf' ? 'd-flex justify-center back' : 'd-flex justify-center'">
      <v-col cols="8">
        <v-list 
          lines="one" 
          :class="GET_PAGE =='rdf' ? 'd-flex justify-space-between w-100 back' : 'd-flex justify-space-between w-100'"
        >        
          <v-list-item
            v-for="n in TITELS"
            :key="n"
            class="itmBtn p-0"
          >
            <v-btn 
              v-if="n == 'nitros'" 
              :href= "GET_PAGE =='start' ?  'https://nitrosbase.com/' : '#' " 
              :ripple=false 
              icon 
              width="200"
              @click.prevent="GET_PAGE !='start' ? goTop() : $router.push('/start')"                        
            >
              <v-img
                v-if="GET_PAGE == 'start'"
                width="200"
                height="50"           
                src="../../assets/NitrosData.png"

              ></v-img>
              <v-img
                v-if="GET_PAGE == 'sql'"
                width="200"
                height="50"           
                src="../../assets/block1.svg"
              ></v-img>
              <v-img
                v-if="GET_PAGE == 'mm'"
                width="200"
                height="50"           
                src="../../assets/block2.svg"
              ></v-img>
              <v-img
                v-if="GET_PAGE == 'rdf'"
                width="200"
                height="47"           
                src="../../assets/block3.svg"
              ></v-img>
              <v-img
                v-if="GET_PAGE == 'im'"
                width="200"
                height="50"           
                src="../../assets/block4.svg"
              ></v-img>
            </v-btn> 
            <v-btn 
              v-if="n == 'About'"
              :ripple=false
            >
              <span v-if="lang == 'en'">About</span>
              <span v-if="lang == 'ru'">О компании</span>
              <v-dialog
                v-model="about"
                activator="parent"
                width="40%"
              >
                <v-card>
                  <v-card-text v-if="lang == 'en'">
                    <h2 class="d-flex justify-center text-h5 font-weight-bold">
                      About NitrosData
                    </h2>
                    <p class="mt-2 text-subtitle-1">
                      <a href="https://nitrosdata.com/" target="blank">NitrosData</a> 
                      is an experienced team of data experts that have been collaborating since the 1980s when we started our careers at the Kurchatov Institute Computing Center and include graduates of MIPT and MEPhI.
                    </p>
                    <p class="mt-2 text-subtitle-1">
                      We have received several awards from prestigious Russian and international IT exhibitions and contests. NitrosData is a resident of the 
                      <a href="https://sk.ru/" target="blank">Skolkovo Innovation Center</a>, member of the 
                      <a href="https://russoft.org//" target="blank">RUSSOFT association</a>.
                    </p>
                    <p class="mt-2 text-subtitle-1">
                      We provide a full range of services for audit, design, implementation and maintenance of high-load information systems.
                    </p>
                    <p class="mt-2 text-subtitle-1">
                      Our professionalism and qualifications allow us to implement complex and large-scale projects, thereby creating unique solutions that provide a competitive advantage for the customer’s business. We also provide telephonic and electronic tech support services.
                    </p>
                  </v-card-text>
                  <v-card-text v-if="lang == 'ru'">
                    <h2 class="d-flex justify-center text-h5 font-weight-bold">
                      О NitrosData
                    </h2>
                    <p class="mt-2 text-subtitle-1">
                      <a href="https://nitrosdata.com/" target="blank">NitrosData</a> 
                      - команда опытных экспертов по управлению данными. Работаем вместе с конца 80-х годов прошлого века. В основе коллектива выпускники МФТИ и МИФИ. Начинали в Вычислительном центре Курчатовского института.
                    </p>
                    <p class="mt-2 text-subtitle-1">
                      Мы неоднократно получали награды престижных российских и международных премий в сфере ИТ. NitrosData - резидент 
                      <a href="https://sk.ru/" target="blank">инновационного центра Сколково</a>, действительный член 
                      <a href="https://russoft.org//" target="blank">ассоциации РУССОФТ</a>.
                    </p>
                    <p class="mt-2 text-subtitle-1">
                      Мы обеспечиваем полный комплекс услуг по аудиту, проектированию, внедрению и сопровождению больших и высоконагруженных информационных систем.
                    </p>
                    <p class="mt-2 text-subtitle-1">
                      Профессионализм и квалификация позволяют нам реализовывать сложные и масштабные проекты; создавать конкурентное превосходство для бизнеса заказчика, разрабатывая уникальные решения, обеспечивая телефонную и электронную сервисную и техническую поддержку.
                    </p>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn color="primary" block @click="about = false">Ok</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-btn>  
            <v-btn 
             v-if="n == 'Download' && GET_PAGE != 'start'"
            >
              <span v-if="lang == 'en'">Download</span>
              <span v-if="lang == 'ru'">Загрузка</span>
              <v-dialog
                v-model="downloadSent"
                activator="parent"
                width="70%"
              >
                <div class="contacts pa-5">
                  <v-row>
                    <v-col
                      cols="12"
                      class="d-flex flex-column"
                    >
                      <h3 v-if="lang == 'ru'" class="text-h5 mb-3">Загрузка</h3>
                      <p v-if="lang == 'ru'" class="text-body-1 mb-3">
                        На указанный E-mail будет отправлено письмо со ссылкой для скачивания.
                      </p>
                      <h3 v-if="lang == 'en'" class="text-h5 mb-3">Download</h3>
                      <p v-if="lang == 'en'" class="text-body-1 mb-3">
                        A link with installation instructions will be sent to your email address.
                      </p>
                      <v-text-field
                        density="compact"
                        variant="outlined"
                        v-model="emailDown"
                        :rules="emailRules"
                        :counter="10"
                        label="Email"
                        required
                        hide-details
                        bg-color="white"
                      ></v-text-field>
                    </v-col>
                    <v-checkbox
                      v-model="checkbox"
                      :label= "lang == 'ru' ?  'Отправляя сообщение, я подтверждаю свое согласие на обработку моих персональных данных, указанных в форме, в целях и пределах, установленных законодательством РФ о персональных данных.' : 'By sending this message, I confirm my consent to the processing of my personal data specified in the form, for the purposes and extent established by the legislation of the Russian Federation on personal data.'"
                      required
                      class="my-checkbox px-3"
                    ></v-checkbox>                        
                    <v-col>
                      <v-btn :disabled = disableBtnDown v-if="lang == 'en'" type="submit" @click="SentMailDown()">Sent</v-btn>
                      <v-btn :disabled = disableBtnDown v-if="lang == 'ru'" type="submit" @click="SentMailDown()">Отправить</v-btn>
                    </v-col>
                  </v-row>
                </div>
              </v-dialog>
              <v-dialog
                v-model="down"
                width="70%"
                >
                <!-- activator="parent" -->
                <template v-slot:default="{ isActive }">
                  <v-card v-if="lang == 'ru'" title="Загрузка">
                    <v-card-text>
                      Спасибо за интерес к NitrosBase.
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        text="Вернуться на главную страницу"
                        @click="isActive.value = false"
                      ></v-btn>
                    </v-card-actions>
                  </v-card>
                  <v-card v-if="lang == 'en'" title="Download">
                    <v-card-text>
                      Thank you for your interest in NitrosBase.
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>                      
                      <v-btn
                        text="Back to home page"
                        @click="isActive.value = false"
                      ></v-btn>
                    </v-card-actions>
                  </v-card>
                </template>            
              </v-dialog> 
            </v-btn>
            <v-btn 
              v-if="n == 'Documentation' && page != 'start'"
              :ripple=false
              @click="$router.push('/docs')"
              >
              <!-- download -->
              <!-- href='./assets/files/NitrosBase_2.0_Manuals.zip' -->
              <span v-if="lang == 'en'">Documentation</span>
              <span v-if="lang == 'ru'">Документация</span>            
            </v-btn>
            <v-btn 
              v-if="n == 'Contacts'"
              :ripple=false
            >
              <span v-if="lang == 'en'">Contacts</span>
              <span v-if="lang == 'ru'">Контакты</span>
              <v-dialog
                v-model="contacts"
                activator="parent"
                width="70%"
              >
                <div class="contacts pa-5">
                  <v-row>
                    <v-col
                      cols="12"
                      md="6"
                      class="d-flex flex-column"
                    >
                      <v-text-field
                        density="compact"
                        variant="outlined"
                        v-model="email"
                        :rules="emailRules"
                        :counter="10"
                        label="Email"
                        required
                        hide-details
                        bg-color="white"
                      ></v-text-field>
                      <v-text-field
                        v-if="lang == 'en'"
                        density="compact"
                        variant="outlined"
                        v-model="firstname"
                        :rules="nameRules"
                        :counter="10"
                        label="Name"
                        required
                        hide-details
                        bg-color="white"
                        class="mt-5"
                      ></v-text-field>
                      <v-text-field
                        v-if="lang == 'ru'"
                        density="compact"
                        variant="outlined"
                        v-model="firstname"
                        :rules="nameRules"
                        :counter="10"
                        label="Имя"
                        required
                        hide-details
                        bg-color="white"
                        class="mt-5"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                    <v-textarea
                      v-model="message"
                      v-if="lang == 'en'"
                      bg-color="white"
                      color="black"
                      label="Message"
                      variant="outlined"
                      no-resize
                    ></v-textarea>
                    <v-textarea
                      v-model="message"
                      v-if="lang == 'ru'"
                      bg-color="white"
                      color="black"
                      label="Сообщение"
                      variant="outlined"
                      no-resize
                    ></v-textarea>
                    </v-col>
                    <v-checkbox
                      v-model="checkbox"
                      :label= "lang == 'ru' ?  'Отправляя сообщение, я подтверждаю свое согласие на обработку моих персональных данных, указанных в форме, в целях и пределах, установленных законодательством РФ о персональных данных.' : 'By sending this message, I confirm my consent to the processing of my personal data specified in the form, for the purposes and extent established by the legislation of the Russian Federation on personal data.'"
                      required
                      class="my-checkbox px-3"
                    ></v-checkbox>                        
                    <v-col>
                      <v-btn :disabled = disableBtn v-if="lang == 'en'" type="submit" @click="SentMail()">Sent</v-btn>
                      <v-btn :disabled = disableBtn v-if="lang == 'ru'" type="submit" @click="SentMail()">Отправить</v-btn>
                    </v-col>
                  </v-row>
                </div>
              </v-dialog>
              <v-dialog
                v-model="isActive"
                width="70%"
                >
                <!-- activator="parent" -->
                <template v-slot:default="{ isActive }">
                  <v-card v-if="lang == 'ru'" title="Спасибо">
                    <v-card-text>
                      Мы получили ваше сообщение и ответим вам на указанный email.
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        text="Вернуться на главную страницу"
                        @click="isActive.value = false"
                      ></v-btn>
                    </v-card-actions>
                  </v-card>
                  <v-card v-if="lang == 'en'" title="Thank You">
                    <v-card-text>
                      We've received your message and will get back to you.
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        text="Back to home page"
                        @click="isActive.value = false"
                      ></v-btn>
                    </v-card-actions>
                  </v-card>
                </template>            
              </v-dialog> 
            </v-btn>
            <v-btn 
              v-if="n == 'Русский'"
              :ripple="false"
              @click="SET_LANG"
            >
              <span>{{ CURENT_LANG }}</span>
              <!-- <span v-if="lang == 'en'">ru</span>
              <span v-if="lang == 'ru'">en</span> -->
            </v-btn>      
          </v-list-item>
        </v-list>      
      </v-col>
    </v-row>
  </v-app-bar>
</template>

<script>
import axios from "axios";
import { apiClient } from "@/axios/api";
import "./HeaderComp.scss";
import { mapGetters, mapActions, mapState } from "vuex";


export default {
  name: "HeaderComponent",
  computed: {
    ...mapGetters(['CURENT_LANG','TITELS','GET_PAGE']),
    ...mapState(['lang','page']),
    disableBtn() {
      if (this.checkbox && this.firstname != '' && this.email != '') {
        return false
      } else {
        return true
      }
    },
    disableBtnDown() {
      if (this.checkbox && this.emailDown != '') {
        return false
      } else {
        return true
      }
    },
    getPath() {
      // console.log('getPath',this.$route.fullPath);
      if (this.$route.fullPath === '/') {
        return false        
      } else {
        return true
      }
    }
  },
  data() {
    return {
      down: false,
      isActive: false,
      checkbox: false,
      about: false,
      contacts: false,
      downloadSent: false,
      // valid: false,
      firstname: '',
      nameRules: [
        value => {
          if (value) return true

          return 'Name is required.'
        },
        value => {
          if (value?.length <= 10) return true

          return 'Name must be less than 10 characters.'
        },
      ],
      email: '',
      emailDown: '',
      emailRules: [
        value => {
          if (value) return true

          return 'E-mail is requred.'
        },
        value => {
          if (/.+@.+\..+/.test(value)) return true

          return 'E-mail must be valid.'
        },
      ],
      message: '',
      emits: ['chageLang']
    }
  },
  methods: {
    ...mapActions(['SET_LANG','setPage']),
    changeLang() {
      console.log('ch_lng');
      this.SET_LANG()
    },
    goTop() {
      window.scrollTo(0, 0);
      this.$router.push('/')
    },
    getHref() {
      return 'NBSQL25-GRA-095.pdf';
    },
    SentMail() {
      // console.log('SentMail Mail: ', this.email);
      // console.log('SentMail Name: ', this.firstname);
      // console.log('SentMail Message: ', this.message);
      // Content-Type: application/x-www-form-urlencoded, 
      // multipart/form-data 
      // text/plain
      //  application/xml.
      const headers = {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
      let url = 'https://NitrosDataAPI.nitrosbase.com/API/SendContactMail'
      let data = {
        email: this.email,
        name: this.firstname,
        text: this.message,
      }
      
     axios.post(url, data, {headers: headers})
       .then((response) => {
          console.log('response',response);
          this.isActive = true         
       })
       .catch((error) => {
         console.log(error.message);
       });
    },
    SentMailDown() {
      // console.log('SentMail Mail: ', this.email);
      // console.log('SentMail Name: ', this.firstname);
      // console.log('SentMail Message: ', this.message);
      let url = 'https://nitrosdataapi.nitrosbase.com/API/SendDownloadMail2'
      const headers = {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
      let data = {
        email: this.emailDown,
      }
      // setInterval(()=> {this.isActive = true},500)
     axios.post(url, data,{headers: headers})
       .then((response) => {
          console.log('res',response);
          this.down = true
          // this.isActive = true
       })
       .catch((error) => {
         console.log(error.message);  
        //  this.down = true       
       });
    }
  }
}
</script>

<style>

</style>