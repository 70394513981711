<template>    
    <v-row class="d-flex justify-center bg-dblue h-100 w-100">
        <v-col cols="8" class="py-16 px-0">
          <h1 v-if="lang == 'ru'" class="text-white text-h4 mb-15">Документация</h1>
          <h1 v-if="lang == 'en'" class="text-white text-h4 mb-15">Documentation</h1>
          <v-row class="d-flex">
            <v-col cols="12">
              <v-list
                class="bg-dblue"
              >
                <v-list-item                   
                  v-for="n in listDocs" 
                  :key="n"
                  class="text-white pl-0"
                >
                  <a :href="`./${ n.href }`" download=""> <span class="textHref">{{ n.name }}</span></a>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import "./index.scss";
export default {
    name: 'docs',
    computed: {
        ...mapState(['lang','page']),
        listDocs() {          
          return this.lang == 'en' ? this.listEn : this.list
        },
    },
    data() {
        return {
          listEn: [
          {name: 'NITROSBASE SQL V2.5 Руководство администратора', href: 'NBSQL25-ADM-037.pdf'},
            {name: 'NITROSBASE SQL V2 Програмный интерфейс С/С++', href: 'NBSQL25-CPP-128.pdf'},
            {name: 'NITROSBASE SQL V2 Работа с графами', href: 'NBSQL25-GRA-095.pdf'},
            {name: 'NITROSBASE SQL V2 Описание языка SQL', href: 'NBSQL25-SQL-024.pdf'},
            {name: 'Функциональные характеристики NitrosBase SQL', href: 'Функциональные характеристики NitrosBase SQL.pdf'},
          ],
          list: [
            {name: 'NITROSBASE SQL V2.5 Руководство администратора', href: 'NBSQL25-ADM-037.pdf'},
            {name: 'NITROSBASE SQL V2 Програмный интерфейс С/С++', href: 'NBSQL25-CPP-128.pdf'},
            {name: 'NITROSBASE SQL V2 Работа с графами', href: 'NBSQL25-GRA-095.pdf'},
            {name: 'NITROSBASE SQL V2 Описание языка SQL', href: 'NBSQL25-SQL-024.pdf'},
            {name: 'Функциональные характеристики NitrosBase SQL', href: 'Функциональные характеристики NitrosBase SQL.pdf'},
          ]
        }
    },
    methods: {
    ...mapMutations(['SET_PAGE']),
    changePage(page) {
      this.$store.commit('SET_PAGE',page)
    }
  }
}
</script>
<style>

</style>